<template>
  <div>
    <v-card class="d-flex justify-end mb-6" flat tile>
      <v-breadcrumbs :items="breadcrumbs" large>
        <template v-slot:divider>
          <v-icon>{{ icons.mdiSlashForward }} </v-icon>
        </template>
      </v-breadcrumbs>
    </v-card>
    <v-card flat class="pa-3 mt-2">
      <v-card-text>
        <v-card-title class="text-no-wrap pt-1 ps-2"> CREATE PERMIT STAY</v-card-title>
        <form @submit.prevent="CreateData" ref="form">
          <v-row>
            <v-col cols="12" v-for="error in errors" :key="error">
              <v-alert color="error" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning"> </v-icon>
                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">{{ error }}</p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field
                label="Permit Stay Name"
                v-model="permitstay.nama_jenispermohonan"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-btn type="submit" color="primary" class="me-3 mt-4"> Save </v-btn>

              <router-link :to="{ name: 'permitstay-index' }">
                <v-btn color="secondary" outlined class="mt-4" type="reset"> Cancel </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mdiSlashForward } from '@mdi/js'
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/admin/dashboard',
        },
        {
          text: 'Permit Stay',
          disabled: false,
          href: '/admin/permitstay',
        },
        {
          text: 'Create Permit Stay',
          disabled: true,
        },
      ],

      // icons
      icons: {
        mdiSlashForward,
      },

      permitstay: {
        nama_jenispermohonan: '',
      },
      errors: [],
    }
  },
  mounted() {
    this.$isLoading(true)
    setTimeout(() => {
      this.$isLoading(false)
    }, 2000)
  },
  methods: {
    CreateData(e) {
      if (this.$data.permitstay.nama_jenispermohonan.length === 0) {
        this.$swal.fire({
          title: 'Error',
          text: 'Permit stay required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else {
        let uri = process.env.VUE_APP_ROOT_API + '/api/jenispermohonans/store'

        this.axios
          .post(uri, this.permitstay)
          .then(response => {
            this.$swal.fire({
              title: 'Success',
              text: 'Permit stay created successfully',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 2000,
            })
            setTimeout(() => {
              this.$router.push({ name: 'permitstay-index' })
            }, 3000)
          })
          .catch(e => {
            this.$swal.fire({
              title: 'Error!',
              text: 'Permit stay failed to create!',
              icon: 'error',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 2000,
            })
          })

        e.preventDefault()
      }
    },
  },
}
</script>
